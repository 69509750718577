body{
  padding-top: 136px;
}
hr{
  border-top: 1px solid #a1a1a1;
  margin: 0;
  &.space-bottom{
    border-top: 0;
  }
  &.space-top{
    border-top: 0;
  }
}
// .Filled.Primary:focus{
//   border: 0!important;
//   outline: 0!important;
//   box-shadow: inset 0 0 0 1px #730f27;
// }
.Plain.Dark:focus{
  // border: 0!important;
  outline: 0!important;
}
