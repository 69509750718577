// Fonts
$font-inter-regular:         'Inter Regular', 'Helvetica Neue', Arial, sans-serif !default;
$font-inter-bold:            'Inter Bold', 'Helvetica Neue', Arial, sans-serif !default;
$font-poppins-medium:        'Poppins Medium', 'Helvetica Neue', Arial, sans-serif !default;
$font-poppins-bold:          'Poppins Bold', 'Helvetica Neue', Arial, sans-serif !default;
//Colors
$color-font-black:           #000000 !default;
$color-gray-50:              #FAFAFA !default;
$color-gray-100:             #f5f5f5 !default;
$color-gray-600:             #757575 !default;
$color-gray-800:             #424242 !default;
$color-gray-900:             #212121 !default;
$color-font-white:           #ffffff !default;
$color-secondary-critical:   #E11900 !default;
$color-emphasys-gum-900:     #730F27 !default;
$color-emphasys-gum-500:     #E61E4D !default;
$color-specialty-purple-600: #653ECC !default;
$color-specialty-orange-50:  #FFF9F8 !default;
$white-navbar:               rgba(255,255,255,1);
$color-pink-title:           #f07894;
$color-black-title:          #212121;
$color-p-red:                #e11900;
$color-gray-line:            #bdbdbd;
