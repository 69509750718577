.checkout{
  h1{
    margin-top: 0!important;
    margin-bottom: 24px;
    p{
      font-family: $font-poppins-medium;
      line-height: 84px;
      letter-spacing: -0.28px;
      font-size: 56px;
      color: $color-pink-title;
      margin-bottom: 0;
    }
  }
  .list-icon-info-simple{
    margin-top: 42px;
    h6{
      color: $color-gray-900;
      line-height: 28px;
      margin-bottom: 12px;
    }
    p{
      font-size: 14px;
      letter-spacing: 0.0025em;
      line-height: 20px;
      color: rgba($color-font-black, .6);
      margin-bottom: 26px;
      margin-left: 57px;
    }
    span{
      width: 28px;
      height: 28px;
      display: inline-block;
      margin-right: 24px;
    }
    .retainers{
      background-image: url("../../../img/icon/retainers.svg");
    }
    .continuous{
      background-image: url("../../../img/icon/continuous.svg");
    }
    .two-months{
      background-image: url("../../../img/icon/two-months.svg");
    }
  }
  .card-gray{
    margin-top: 24px;
    background-color: $color-gray-50;
    border-radius: 8px;
    padding: 24px;
    h5{
      color: $color-gray-900;
    }
    p{
      color: rgba($color-font-black, .6);
    }
    a{
      color: $color-emphasys-gum-500;
      font-family: $font-inter-bold;
    }
  }
  .Button.Primary{
    width: 100%;
    margin-top: 32px;
  }
  hr{
    margin: 0;
  }
  .total{
    margin-top: 30px;
    margin-bottom: 30px;
    color: $color-gray-600;
    span{
      font-family: $font-poppins-bold;
      font-size: 24px;
      color: $color-font-black;
      float: right;
    }
  }
  h5{
    margin-bottom: 12px;
  }
  .summary-footer{
    font-size: 14px;
    letter-spacing: 0.0025em;
    line-height: 20px;
    color: rgba($color-font-black, .6);
    margin-top: 32px;
  }
  .terms-text {
    color: $color-gray-600;
    font-size: 16px;
    line-height: 1.5;
    letter-spacing: 0.08px;
  }
  .aligners{
    margin-bottom: 24px;
    h4{
      margin-bottom: 6px;
    }
    p{
      margin: 0;
      color: $color-gray-600;
    }
    span{
      height: 32px;
      width: 32px;
      margin-right: 8px;
      display: inline-block;
    }
    .upper{
      span{
        background-image: url("../../../img/icon/aligner.svg");
      }
    }
    .lower{
      padding: 0 40px 0 auto;
      span{
        transform: rotate(180deg);
        background-image: url("../../../img/icon/aligner.svg");
      }
    }
    .treatment-date{
      border-color: black;
      border-style: solid;
      border-right-width: 0;
      border-left-width: 1px;
      border-top-width: 0;
      border-bottom-width: 0;
      // border-width: 1px;
      padding: 0 0 0 40px;
      span{
        background-image: url("../../../img/icon/calendar.svg");
      }
    }
  }
  .checkout-summary{
    position: relative;
    margin-top: 24px;
    img{
      width: 64px;
      height: 64px;
      border-radius: 50%;
      position: absolute;
      top: 0;
      right: 0;
    }
    p{
      font-size: 14px;
      letter-spacing: 0.0025em;
      line-height: 20px;
      color: rgba($color-font-black, .6);
      margin-bottom: 24px;
    }
  }
}
@media (max-width: 991.98px){
  .checkout{
    .Button.Primary{
      margin-top: 24px;
    }
    h1{
      font-size: 24px;
      line-height: 36px;
      margin-bottom: 40px;
      p{
        font-size: 24px;
        line-height: 32px;
      }
    }
    h5{
      font-size: 16px;
      line-height: 24px;
      color: $color-gray-900;
      margin-bottom: 24px;
      text-align: center;
    }
    .aligners{
      h4{
        font-size: 32px;
        margin-bottom: 5px;
      }
      .treatment-date{
        border-width: 0;
        margin-top: 32px;
        padding-left: 0;
      }
      p{
        margin-bottom: 32px;
      }
    }
    .checkout-summary{
      text-align: center;
      img{
        position: initial;
        margin-bottom: 24px;
      }
    }
  }
}
