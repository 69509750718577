.card-price{
  border-radius: 16px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.16);
  // padding: 40px;
  margin: 24px;
  h3{
    margin: 24px 0 16px 0;
  }
  h3.emphasys{
    color: $color-emphasys-gum-500;
  }
  h6{
    margin: 0;
  }
  h6 .before{
    text-decoration-line: line-through;
  }
  h6 .before-description{
    margin-left: 16px;
    color: $color-secondary-critical;
  }
}

.card-price-content{
  padding: 40px 36px 32px 40px;
}

.affirm-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.affirm-divider {
  border-color: $color-gray-line;
}

.affirm-logo {
  // background-image: url("../../../img//icon/affirm-logo.svg");
  width: 80px;
  height: 32px;
  margin-right: 32px;
}

.affirm-text {
  font-family: $font-inter-regular;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.05px;
  text-align: left;
  color: $color-gray-600;
  max-width: 310px;
}

@media (max-width: 991.98px) {
  .card-price:first-child{
    margin: 37px 0 0 0;
  }
  .card-price{
    // padding: 24px;
    margin: 20px 0 32px 0;
    h3{
      font-size: 24px;
      line-height: 36px;
      margin: 16px 0 8px 0;
    }
  }

  .card-price-content{
    padding: 24px;
  }

  .affirm-logo {
    width: 60px;
    height: 24px;
    margin-right: 27px;
  }

  .affirm-text {
    font-size: 12px;
    line-height: 1.43;
    letter-spacing: 0.04px;
    max-width: 192px;
  }
}

@media (max-width: 375px) {
  .affirm-text {
    max-width: 160px;
  }
}