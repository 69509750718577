h1,
h2,
h3,
h4{
  font-family: $font-poppins-bold;
}
h5,
h6{
  font-family: $font-poppins-medium;
}
p,
label,
.legal{
  font-family: $font-inter-regular;
}
a{
  font-family: $font-inter-bold;
}
h1{
  color: $color-font-black;
  font-size: 56px;
  line-height: 1.5;
  letter-spacing: -0.5px;
}
h2{
  color: $color-font-black;
  font-size: 40px;
  line-height: 1.5;
  letter-spacing: 0;
}
h3{
  color: $color-gray-900;
  font-size: 32px;
  line-height: 48px;
  letter-spacing: 0.08px;
  margin: 24px 0;
}
h4{
  font-size: 24px;
  letter-spacing: 0;
  line-height: 36px;
  color: $color-font-black;
}
h5{
  font-size: 20px;
  letter-spacing: 0.0015em;
  line-height: 28px;
  color: $color-gray-600;
}
h6{
  font-size: 16px;
  letter-spacing: 0.0015em;
  line-height: 24px;
  color: $color-gray-600;
}
p,
.legal{
  font-size: 16px;
  letter-spacing: 0.005em;
  line-height: 24px;
  color: $color-font-black;
}
a:not(.Button){
  color: $color-emphasys-gum-500;
}
.legal{
  color: $color-font-white;
  a{
    color: $color-font-white;
    font-family: $font-inter-regular;
  }
}
.color-font-black{
  color: $color-font-black;
}
