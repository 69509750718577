.step-content{
  background-color: $color-specialty-orange-50;
  border-radius: 16px;
  padding: 40px;
}
.step-title.collapsed{
  color: #bdbdbd;
  span{
    background-color: #a582ff;
  }
}
.step-content.collapsed{
  position: relative;
  background-color: $color-gray-100;
}
.step-content .image{
    overflow:hidden;
    border-radius: 16px;
}
.step-content .thumbnail{
  opacity: .6;
  width: 104px;
  height: 72px;
  border-radius: 8px;
  -ms-flex: 0 0 104px;
  flex: 0 0 104px;
}
.step-content .thumbnail img{
  height: 100%;
}
.step-content .description.collapsed{
  padding: 0;
  margin-left: 48px;
}
.step-content img{
  position: absolute;
    top:-50%; left:-50%; right: -50%; bottom:-50%;
    margin: auto;
    height: 100%;
}
.step-content .description{
  padding: 0 0 0 57px;
}
.step-content .description ul li{
  list-style: none;
  font-size: 14px;
  letter-spacing: 0.0025em;
  line-height: 20px;
  color: rgba($color-font-black, .6);
  margin-bottom: 16px;
  text-indent: -31px;
  padding-left: 31px;
}
.step-content .description ul{
  margin-top: 32px;
  margin-bottom: 32px;
  padding: 0;
}
.step-content .description ul li span{
  background-image: url("../../../img/icon/check-circle.svg");
  width: 20px;
  height: 20px;
  display: inline-block;
  color: $color-font-white;
  margin-right: 11px;
  vertical-align: middle;
  line-height: 20px;
}
.step-title span{
  background-color: $color-specialty-purple-600;
  width: 40px;
  height: 40px;
  display: inline-block;
  border-radius: 8px;
  color: $color-font-white;
  margin-right: 23px;
  text-align: center;
  padding-top: 2px;
  text-indent: 0;
}
.step-title.check span{
  background-image: url("../../../img/icon/check.svg");
  background-position: center;
  background-color: $color-specialty-purple-600;
}
.step-title.check{
  color: $color-font-black;
}
.step-title{
  margin-bottom: 26px;
  text-indent: -53px;
  padding-left: 53px;
}
a:hover {
  text-decoration: none !important;
}
.customer-name {
  font-family: $font-poppins-medium;
  line-height: 1.5;
  letter-spacing: -0.28px;
  font-size: 24px;
  color: $color-pink-title;
}
.home-title {
  font-size: 24px;
}
// .step-content .Button{
//   border-radius: 8px;
// }
.step-content .Button.Dark{
  margin-left: 24px;
  margin-right: 24px;
}
@media (max-width: 767px) {
  .step-content .thumbnail{
    margin-bottom: 24px;
  }
  .step-content .description.collapsed{
    flex: 0 0 100%;
    -ms-flex: 0 0 100%;
    margin-left: 0;
  }
}
@media (min-width: 700px) {
  .customer-name, .home-title {
    font-size: 56px;
  }
}
@media (max-width: 960px) {
  .step-content img {
    position: initial;
    padding: 0;
    width: 100%;
  }
  .step-content .thumbnail img {
    width: auto;
    margin-left: -24px;
  }
  .step-content .image{
    position: initial;
    padding: 0;
    width: 100%;
    margin-bottom: 40px;
  }
  .step-content .description{
    padding: 0;
  }
}
@media (max-width: 576px) {
  .step-content{
    padding: 24px;
  }
  .step-content .image{
    margin-bottom: 24px;
    border-radius: 8px;
  }
  .step-content h5{
    font-size: 16px;
    line-height: 24px;
  }
  .step-content .description ul {
    margin-top: 24px;
    margin-bottom: 32px;
  }
  .step-content .Button{
    width: 100%;
  }
  .step-content .Button.Dark{
    margin-top: 24px;
    margin-left: 0;
    margin-right: 0;
  }
  .step-title{
    font-family: $font-poppins-medium;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: 0.03px;
  }
  .step-title span{
    width: 32px;
    height: 32px;
    margin-right: 14px;
  }
}
