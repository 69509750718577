.banner{
  width: 100%;
  min-height: 40px;
  height: 40px;
  background-color: $color-emphasys-gum-900;
  .text{
    width: 100%;
    display: inline-block;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-top: auto;
    margin-bottom: auto;
    font-size: 14px;
    line-height: 20px;
    color: $color-font-white;
    text-align: center;
    a{
      color: $color-font-white;
    }
  }
}
@media (max-width: 490px){
  .banner{
    height: auto;
  }
}
