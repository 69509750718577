footer{
  background-color: $color-emphasys-gum-900;
  padding-top: 64px;
  padding-bottom: 72px;
  .legal ul{
    margin-top: auto;
    margin-bottom: auto;
    padding-left: 0;
    li{
      display: inline;
      padding-right: 5px;
    }
    li:before {
      content: '-';
      margin-right: 5px;
    }
    li:first-child:before{
      content: '';
      margin-right: 0;
    }
  }
  .social-list{
    float: right;
    li{
      list-style: none;
      float: left;
      margin: 0 0 0 24px;
    }
    li:first-child{
      margin: 0 0 0 0;
    }
  }
  hr{
    margin-top: 24px;
    margin-bottom: 24px;
    border: 0;
    border-top: 1px solid rgb(255, 255, 255);
  }
}
@media (max-width: 768px){
  footer {
    padding-top: 56px;
    padding-bottom: 56px;
    .legal ul{
      li{
        display: block;
        margin-top: 24px;
      }
      li:first-child{
        margin-top: 0;
      }
      li:before {
        content: '';
        margin-right: 0;
      }
    }
    hr{
      margin-top: 43px;
      margin-bottom: 32px;
    }
  }
}
@media (max-width: 370px){
  footer .social-list li{
    margin: 0 0 0 15px;
  }
}
