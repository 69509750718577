.modal {
  width: 100%;
  min-height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
}

.no-scroll-modal {
  height: 100vh;
  overflow-y: hidden;
  padding-right: 15px; 
}

.SealMoon > img {
  position: absolute;
  top: 16px;
  left: 16px;
  height: 124px;
  width: 124px;
}

.SealRing {
  height: auto;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}

.SealRing > img {
  height: 156px;
  width: 156px;
  animation-name: spin;
  animation-duration: 1000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}