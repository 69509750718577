.auth{
  width: 500px;
  height: auto;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}
.auth .wrap{
  margin: 24px;
  border-radius: 8px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  text-align: center;
  background-color: #ffffff;
}
.auth .wrap .content {
  padding: 0 24px 40px 24px;
  margin: 0;
}
.auth .wrap .logo {
  height: 72px;
  width: 72px;
  background-image: url("../../../img/icon/logo-circle.svg");
  margin: 40px auto 40px auto;
}
.auth p{
  color: $color-gray-600;
}
.auth h5,
.auth{
  margin-bottom: 24px;
}
.auth .Button{
  width: 100%;
  border-radius: 8px;
  margin-top: 24px;
}
.auth .InnerContainer,
.auth .Input,
.auth input{
  width: 100%;
  border-radius: 8px;
}
