.image-info span{
  width: 96px;
  height: 96px;
  background-color: #fff0ee;
  border-radius: 16px;
  margin-right: 24px;
}
.image-info span img{
  width: 100%;
  height: 100%;
}
.image-info h5{
  color: $color-gray-900;
  margin-top: 4px;
  margin-bottom: 12px;
}
.image-info p{
  color: rgba($color-font-black, .6);
  padding-left: 120px;
}
@media (max-width: 991.98px){
  .image-info span{
    width: 32px;
    height: 32px;
    border-radius: 4px;
    margin-right: 16px;
  }
  .image-info h5{
    margin-bottom: 16px;
  }
  .image-info p{
    padding-left: 48px;
  }
}
