.list-icon-info{
  display: block;
  span{
    width: 24px;
    height: 24px;
    margin-right: 12px;
  }
  p{
    color: $color-gray-600;
    padding-left: 36px;
    margin-bottom: 24px;
  }
}
