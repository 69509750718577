.TestimonialsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 20px 0;
}

.CardsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 32px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #f9f9f9;
  border-radius: 16px;
}

.Item {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 300px;
}

.Body {
  display: flex;
  flex-direction: column;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
  padding: 24px;
  min-height: 133px;
  height: 100%;
}

.Name {
  font-family: $font-poppins-medium;
  font-size: 20px;
  color: $color-gray-900;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: 0.03px;
  text-align: left;
  margin: 0;
}

.Month {
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: 0.18px;
  color: $color-gray-900;
  margin: 8px 0 0;
}

.Description {
  color: $color-gray-600;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.2px;
  text-align: left;
  margin: 8px 0 0;
}

.Header {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  max-width: 300px;
  width: 100%;
  height: 225px;
  min-height: 225px;
}

.Before {
  position: absolute;
  top: 0;
  left:0;
}

.CardsContainer,
img,
.Bar {
  overflow: hidden;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.Before, .After {
  max-width: 300px;
  vertical-align: middle;
  max-height: 225px;
  width: 300px;
  object-fit: cover;
}

.Bar {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 10;
}

.Bar:before,
.Bar:after {
  position:absolute;
  left:50%;
  content: "";
  cursor: -webkit-grab;
  cursor: grab;
}

.Bar:before {
  top: 0;
  transform: translateX(-50%);
  height: 100%;
}

.Bar:after {
  top: 50%;
  transform: translate(-50%, -50%);
  height: 100%;
  border-radius: 5px;
}

.Divider {
  display: flex;
  width: 6px;
  height: 100%;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 3px 0px;
  background-color: rgb(255, 255, 255);
  cursor: ew-resize;
}


.IconBefore > svg {
  display: block;
  transform: rotate(180deg);
  padding-left: 6px;
  cursor: ew-resize;
  margin-top: 5px;
}

.IconAfter > svg {
  padding-left: 6px;
  margin-top: 2px;
  cursor: ew-resize;
}

.IconAfter svg path, .IconBefore svg path {
  filter: drop-shadow(rgba(0, 0, 0, 0.2) 0px 0px 3px);
  fill: rgb(255, 255, 255);
  stroke: transparent;
}

.ButtonBefore {
  bottom: 12px;
  left: 12px;
}

.ButtonAfter {
  right: 12px;
  bottom: 12px;
}


.ButtonBefore, .ButtonAfter {
  padding: 5px 6px 4px 7px;
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  cursor: pointer;
  z-index: 200;
  font-family: ModernEraBold;
  font-size: 12px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: normal;
  text-align: left;
  color: #ffffff;
  outline: none;
  text-transform: uppercase;
}

//  section

.TitleSection {
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  font-size: 24px;
}

.SubTitleSection {
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.02px;
}

.SliderTestimony {
  display: flex;
  flex-direction: column;
}

.ContainerCard {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: 0 30px;
}

@media (min-width: 767.98px) {
  .CardsContainer {
    flex-direction: row;
    align-items: stretch;
    margin-top: 40px;
    max-width: 394px;
    height: 450px;
  }

  .TitleSection {
    line-height: 1.5;
    letter-spacing: 0.08px;
    font-size: 32px;
  }

  .SubTitleSection {
    line-height: 1.4;
    letter-spacing: 0.03px;
    font-size: 20px;
  }
}
