.before-after{
  display: flex;
  flex-direction: column;
  .container-card {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin: 0 30px;
  }
  .title-section {
    color: $color-black-title;
  }
  .subtitle-section {
    color: $color-gray-600;
  }
}
