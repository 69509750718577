@font-face {
  font-family: 'Inter Regular';
  src: local('Inter Regular'), url('../../fonts/Inter-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'Inter Bold';
  src: local('Inter Bold'), url('../../fonts/Inter-Bold.ttf') format('truetype');
}
@font-face {
  font-family: 'Poppins Medium';
  src: local('Poppins Medium'), url('../../fonts/Poppins-Medium.ttf') format('truetype');
}
@font-face {
  font-family: 'Poppins Bold';
  src: local('Poppins Bold'), url('../../fonts/Poppins-Bold.ttf') format('truetype');
}