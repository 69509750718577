.video-square{
  // max-width: 520px;
  // height: 448px;
  // width: 100%;
  // height: auto;
  // border-radius: 24px;
  overflow: hidden;
  video{
    width: 100%;
    height: auto;
    // width: 100%;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    // height: 100%;
    background-color: $color-gray-50;
    border-radius: 24px;
  }
  // .play{
  //   width: 80px;
  //   height: 80px;
  //   background-color: rgba(253, 233, 237, 0.5);
  //   z-index: 1;
  //   position:absolute;
  //   border-radius: 50%;
  //   margin:auto;
  //   top:144px;
  //   left:0;
  //   right:0;
  //   .triangle {
  //     width: 0;
  //     height: 0;
  //     border-top: 18px solid transparent;
  //     border-left: 23px solid red;
  //     border-bottom: 18px solid transparent;
  //     position: absolute;
  //     margin:auto;
  //     top:0;
  //     bottom:0;
  //     left:0;
  //     right:0;
  //   }
  // }
}
@media (max-width: 991.98px){
  .video-square{
    max-width: 100%;
    .play{
      top:340px;
    }
  }
}

.planning-lab-video {
  object-fit: contain;
  height: 100% !important;
  width: 100% !important;
  border-radius: 24px !important;
}

.planning-lab-video-desktop {
  max-height: 448px;
  max-width: 520px;
  object-fit: cover;
  height: 100% !important;
  width: 100% !important;
  border-radius: 24px !important;
}

.planning-lab-cta {
  position: absolute;
  padding: 8px;
  background: rgba(33, 33, 33, 0.8);
  border-radius: 100px;
  font-family: $font-inter-bold !important;
  color: $color-font-white !important;
  font-size: 16px !important;
  line-height: 24px !important;
  letter-spacing: 0.0125em !important;
  margin-bottom: 0 !important;
  top: -16px;
  left: 0;
}

@media (min-width: 375px) {
  .planning-lab-cta {
    left: 6%;
    padding: 16px;
  }
}

@media (min-width: 414px) {
  .planning-lab-cta {
    left: 10%;
  }
}

@media (min-width: 500px) {
  .planning-lab-cta {
    left: 19%;
  }
}

@media (min-width: 700px) {
  .planning-lab-cta {
    left: 23%;
  }
}

@media (min-width: 800px) {
  .planning-lab-cta {
    left: 30%;
  }
}

@media (min-width: 992px) {
  .planning-lab-cta {
    left: 25%;
  }
}