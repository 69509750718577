nav {
  background-color: $white-navbar;
  height: 136px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.08);
}
.navbar{
  padding: 0;
  align-items: self-end;
}
@media (max-width: 768px) {
  nav{
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.28);
  }
  .navbar img{
    width: 106px;
    height: 24px;
  }
  .navbar-text{
    font-size: 11px;
    letter-spacing: 0.004em;
    line-height: 16px;
    color: $color-gray-600;
    text-align: right;
  }
  .navbar-text a{
    display: block;
  }
}
.logout-header{
  border-bottom: 1px solid #bdbdbd;
  padding: 24px!important;
  p{
    font-family: $font-poppins-medium;
    text-align: left;
    margin-bottom: 0;
    font-size: 20px;
    line-height: 28px;
    color: $color-gray-900!important;
  }
  .close{
    width: 24px;
    height: 24px;
    background-image: url("../../../img/icon/close.svg");
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center;
    cursor: pointer;
  }
}
.logout-body{
  padding: 24px!important;
  p{
    text-align: left;
    margin-bottom: 0;
    font-size: 16px;
    line-height: 24px;
    color: $color-gray-600!important;
  }
}
