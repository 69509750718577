.price{
  h5{
    margin-top: 24px;
    margin-bottom: 32px;
  }
}
@media (max-width: 768px){
  .price{
    h2{
      font-size: 24px;
      line-height: 36px;
    }
  }
}
