.planning{
  hr.space-top{
    margin-top: 48px;
  }
  hr.space-bottom{
    margin-bottom: 88px;
  }
  .faq{
    margin-top: 72px;
  }
  .before-after,
  .list-image-info,
  .price{
    margin-top: 72px;
    margin-bottom: 72px;
  }
  .checkout{
    margin-bottom: 72px;
  }
  .space-before-after-bottom {
    margin-top: 56px;
  }
}
@media (min-width: 700px) {
  .space-before-after-bottom {
    margin-top: 72px;
  }
}
@media (max-width: 768px){
  .planning{
    hr.space-top{
      margin-top: 40px;
    }
    .list-image-info{
      h3{
        font-size: 24px;
        line-height: 36px;
      }
    }
  }
}
