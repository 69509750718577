
@media (min-width: 1440px) {
  .container, .container-lg, .container-md, .container-sm, .container-xl{
      max-width: 1232px;
  }
}
@media (max-width: 575.98px){
  .container{
    padding-left: 24px;
    padding-right: 24px;
  }
  section .row {
    margin-right: -18px;
    margin-left: -18px;
  }
}
@media (max-width: 768px) {
  body {
    padding-top: 70px;
  }
}
