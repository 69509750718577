.waiting {
  border-radius: 16px;
  background-color: #fff9f8;
}

.waiting .apollo {
  height: 144px;
  width: 144px;
  background-image: url("../../../img/icon/apollo.svg");
}

.waiting .apollo-no-cand {
  height: 144px;
  width: 144px;
  background-image: url("../../../img/icon/apollo-no-cand.svg");
}

.waiting .title {
  line-height: 1.5;
  letter-spacing: normal;
  color: $color-black-title;
  text-align: center;
}

.waiting .waiting-p {
  font-family: $font-poppins-medium;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.02px;
  text-align: center;
  color: $color-gray-600;
}

.waiting .par-no-cand {
  margin-top: 24px;
}

.waiting .contact {
  margin-top: 24px;
  margin-bottom: 56px;
}

.waiting .title-no-cand {
 margin-bottom: 0;
}

@media (min-width: 700px) {
  .waiting {
    max-width: 1040px;
    margin: 0 auto;
  }

  .waiting-spacer {
    height: 40px;
  }

  .waiting .title {
    font-size: 40px;
    max-width: 400px;
    margin: 0 auto;
  }

  .waiting .title-no-cand {
    max-width: 538px;
  }

  .waiting .waiting-p {
    font-size: 20px;
  }
  
  .waiting .par1 {
    max-width: 693px;
    margin: 0 auto;
  }

  .waiting .par2 {
    max-width: 745px;
    margin: 0 auto;
  }

  .waiting .par-no-cand {
    margin-top: 32px;
    max-width: 840px;
  }

  .waiting .contact {
    margin-top: 32px;
    margin-bottom: 72px;
    width: 231px;
  }
}

@media (min-width: 1000px) {
  .waiting {
    width: 80%;
  }
}