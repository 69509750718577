.accordion{
  span{
    height: 22px;
    width: 22px;
    background-image: url("../../../img/icon/expand.svg");
    position: absolute;
    right: 0;
  }
  .expand{
    background-position: top;
  }
  .contract{
    background-position: bottom;
    transform: rotate(180deg);
  }
  .card{
    border-radius: 0;
    border-left: 0;
    border-right: 0;
  }
  .card button{
    text-align: left;
    padding: 24px 50px 24px 0;
    font-family: $font-poppins-medium;
    font-size: 20px;
    letter-spacing: 0.2px;
    line-height: 28px;
    color: $color-gray-900;
  }
  .card{
    .card-body{
      font-family: $font-inter-regular;
      padding-top: 0;
      padding-bottom: 24px;
      padding-left: 0;
      padding-right: 0;
      font-size: 16px;
      letter-spacing: 0.005em;
      line-height: 24px;
      color: $color-gray-600;
    }
    .btn-link.focus, .btn-link:focus, .btn-link:hover {
      text-decoration: none;
    }
    .btn.focus, .btn:focus {
      box-shadow: none;
    }
  }
}
