@import url('https://fonts.googleapis.com/icon?family=Material+Icons');
.back-btn-container {
  margin-bottom: 40px;
  margin-top: 40px;
}

.about-title {
  font-family: $font-poppins-medium;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.02px;
  text-align: left;
  color: $color-pink-title;
}

.title-more-info-impressions {
  font-family: $font-poppins-bold;
  font-size: 24px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.02px;
  text-align: left;
  color: $color-black-title;
}

.p-more-info {
  color: $color-gray-600;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.08px;
  text-align: left;
}

.p-red {
  color: $color-p-red !important;
  font-family: $font-inter-regular !important;
}

.impressions-video {
  display: block;
  background-color: white;
  border-radius: 16px;
  width: 100%;
  height: auto;
  object-fit: cover;
  outline: none;
}

.about-process-title {
  font-family: $font-poppins-medium;
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: 0.03px;
  text-align: left;
  color: $color-font-black;
  border-bottom: solid 1px $color-gray-line;
}

.about-process-link {
  font-family: $font-inter-regular;
  color: $color-font-black !important;
}

.about-process-link:hover {
  color: $color-font-black !important;
}

.about-process-arrow {
  color: $color-emphasys-gum-500;
}

.btn-slider {
  outline: none;
  border: none;
  padding: 8px;
  background-color: #ffffff;
  border-radius: 50%;
  position: absolute;
  z-index: 2;
  top: calc(50% - 15px);
  width: 40px;
  height: 40px;
  cursor: pointer;
}

.icon-red {
  color: $color-emphasys-gum-500;
}

.indicator-slider {
  width: 16px;
  height: 16px;
  display: inline-block;
  margin: 0 8px;
  background: #9e9e9e;
  border-radius: 50%;
}

.indicator-slider-active {
  background: $color-emphasys-gum-500;
}

.carousel-title {
  line-height: 1.5;
  letter-spacing: normal;
  color: $color-black-title;
}

.more-time-section {
  background-color: #f5f5f5;
}

.more-time-title {
  line-height: 1.5;
  letter-spacing: normal;
  color: $color-black-title;
}

.more-time-desc {
  line-height: 1.5;
  letter-spacing: 0.08px;
  color: $color-gray-600;
}

.more-time-desc > span, .p-more-info > span {
  font-family: $font-inter-bold;
  letter-spacing: 0.2px;
  color: $color-gray-900;
}

.sticky-about-process {
  position: -webkit-sticky;
  position: sticky;
  bottom: 50%;
  right: 0;
  z-index: 2;
  background-color: #ffffff;
  width: 100%;
  height: 0;
  max-width: 400px;
  display: none;
}

.mi-photos-subtitle {
  margin-top: 56px;
  margin-bottom: 16px;
  line-height: 1.5;
  letter-spacing: 0.08px;
}

.mi-photos-listTitle {
  margin-bottom: 24px;
  line-height: 1.5;
  letter-spacing: 0.02px;
  font-weight: 500;
}

.mi-photos-listItem {
  line-height: 1.43;
  letter-spacing: 0.18px;
  font-size: 14px;
  color: $color-gray-600;
  margin: 0;
}

.photos-page-no-kit-returned {
  font-family: $font-inter-bold;
}

.photos-page-span-spacer {
  margin-bottom: 48px;
}

@media (min-width: 700px) {
  .impressions-video {
    max-height: 425px;
  }

  .about-title {
    font-size: 24px;
    letter-spacing: normal;
  }

  .title-more-info-impressions {
    font-size: 40px;
    letter-spacing: normal;
  }

  .back-btn-container {
    margin-top: 56px;
  }

  .max-width-content {
    max-width: 712px;
  }
}

@media (min-width: 1200px) {
  .sticky-about-process {
    display: block;
    margin: 0 0 0 auto;
  }
}